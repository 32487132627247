import axios from 'axios';

import { MapLayer } from 'data/capas/capas';
import { User } from 'data/users';
import { apiUrl, getApiToken } from 'helpers/utils';

export interface MapPolygon {
  _id: string;
  polygonOwner: User | undefined;
  polygonName: string;
  polygonDescription: string;
  polygonLayer: MapLayer | undefined;
  polygonCoords: string[];
  polygonStatus: string;
  polygonInsertDate: Date;
  polygonExpireDate: Date;
}

export const fetchPolygons = async (): Promise<MapPolygon[]> => {
  try {
    const headers = {
      Authorization: getApiToken(),
    };
    const response = await axios.get(apiUrl + '/api/polygon/list', { headers });
    
    return response.data.content;
  } catch (error) {
    console.error('Error fetching polygons:', error);
    return [];
  }
}; 
