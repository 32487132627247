import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, ChangeEvent, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import axios from 'axios'; // Importa axios

import { User, fetchUsers } from 'data/users';

import MembersTable, {
  membersTableColumns
} from 'components/tables/MembersTable';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { apiUrl } from 'helpers/utils';
import CheckmarkAnimation from './apps/poligonos/modal_anim/CheckmarkAnimation';
import CrossAnimation from './apps/poligonos/modal_anim/CrossAnimation';

const Members = () => {
  const [membersData, setMembersData] = useState<User[]>([]);
  const [show, setShow] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  
  // Estado para almacenar los datos del formulario
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    rut: '',
    institution: '',
    city: '',
    region: '',
    role: 'USER',
    groups: [],
    permissions: [],
    lastIp: '',
    picture: ''
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    (async () => {
      setMembersData(await fetchUsers());
    })();
  }, []);
  
  const table = useAdvanceTable({
    data: membersData,
    columns: membersTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const resetForm = () => {
    setFormData({
      username: '',
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      rut: '',
      institution: '',
      city: '',
      region: '',
      role: 'USER',
      groups: [],
      permissions: [],
      lastIp: '',
      picture: ''
    });
  };

  const handleSave = async () => {
    try {
      console.log(JSON.stringify(formData))

      await axios.post(apiUrl +'/api/user/signup', formData);

      resetForm();

      handleClose();
      
      setMembersData(await fetchUsers());

      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error al guardar el usuario:', error);
      setShowErrorModal(true);
    }
  };

  return (
    <>
    <div>
      <div className="mb-9">
        <h2 className="mb-5">Administración de Usuarios</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-3">
            <Row>
              <Col className="mb-3" xs={10} md={10} lg={10} xl={10} xxl={10} sm={10}>
                <SearchBox
                  placeholder="Buscar Usuarios"
                  onChange={handleSearchInputChange}
                />
              </Col>
              <Col xs={7} md={3} lg={3} xl={3} xxl={4} sm={7}>
                <Button variant="outline-primary" onClick={handleShow} className="me-2 px-2">
                  <FontAwesomeIcon icon={faPlus} className="fs-9 me-2" />
                  Agregar Usuario
                </Button>
              </Col>
                <Modal size="lg" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Agregar Usuario</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Row xs={1} md={2} lg={4} xl={4} xxl={4} sm={7}>
                        <Form.Group controlId="Usuario" as={Col}>
                          <Form.Label>Usuario</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="username"
                            placeholder="juanalberto"
                            value={formData.username}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="Nombre" as={Col}>
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="firstName"
                            placeholder="Nombre"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="ApellidoP" as={Col}>
                          <Form.Label>Apellido</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="lastName"
                            placeholder="Apellido Paterno"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="Rut" as={Col}>
                          <Form.Label>R.U.T / DNI</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="rut"
                            placeholder="11111111-1"
                            value={formData.rut}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row xs={1} md={2} lg={4} xl={4} xxl={4} sm={7}>
                        <Form.Group controlId="Email" as={Col}>
                          <Form.Label>Email</Form.Label>
                          <Form.Control 
                            type="email" 
                            name="email"
                            placeholder="nombre@xgis.cl" 
                            autoFocus
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="institution" as={Col}>
                          <Form.Label>Institución</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="institution"
                            placeholder="Institución"
                            value={formData.institution}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="city" as={Col}>
                          <Form.Label>Ciudad</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="city"
                            placeholder="Ciudad"
                            value={formData.city}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="Region" as={Col}>
                          <Form.Label>Región</Form.Label>
                          <Form.Select 
                            name="region"
                            value={formData.region}
                            onChange={handleChange}
                          >
                            <option value="Elegir...">Elegir...</option>
                            <option value="I - Tarapacá">I - Tarapacá</option>
                            <option value="II - Antofagasta">II - Antofagasta</option>
                            <option value="III - Atacama">III - Atacama</option>
                            <option value="IV - Coquimbo">IV - Coquimbo</option>
                            <option value="V - Valparaiso">V - Valparaiso</option>
                            <option value="VI - O'higgins">VI - O'higgins</option>
                            <option value="VII - Maule">VII - Maule</option>
                            <option value="VIII - Bío Bío">VIII - Bío Bío</option>
                            <option value="IX - Araucanía">IX - Araucanía</option>
                            <option value="X - Los Lagos">X - Los Lagos</option>
                            <option value="XI - Aysén">XI - Aysén</option>
                            <option value="XII - Magallanes">XII - Magallanes</option>
                            <option value="RM - Metropolitana">RM - Metropolitana</option>
                            <option value="XIV - Los Ríos">XIV - Los Ríos</option>
                            <option value="XV - Arica y Parinacota">XV - Arica y Parinacota</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      
                      <Row xs={1} md={2} lg={4} xl={4} xxl={4} sm={7}>
                        <Form.Group controlId="Numero" as={Col}>
                          <Form.Label>Número</Form.Label>
                          <Form.Control 
                            type="text" 
                            name="phone"
                            placeholder="+56900000000"
                            value={formData.phone}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row xs={1} md={1} lg={1} xl={1} xxl={1} sm={7}>
                        <Form.Group controlId="password" as={Col}>
                          <Form.Label>Contraseña</Form.Label>
                          <Form.Control 
                            type="password" 
                            name="password"
                            placeholder="Contraseña"
                            value={formData.password}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Row>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                      Guardar
                    </Button>
                  </Modal.Footer>
                </Modal>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis border-top border-bottom border-translucent position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>

    <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Usuario insertado correctamente.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          Hubo un error al insertar el usuario.<br />
          Inténtelo nuevamente más tarde.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Members;