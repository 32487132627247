import { useState, useEffect } from 'react';
import { MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { Link, useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  faListAlt
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import WarningAnimation from '../poligonos/modal_anim/WarningAnimation';
import { ButtonGroup } from 'react-bootstrap';
import axios from 'axios';
import { apiUrl, getApiToken } from 'helpers/utils';
import CrossAnimation from '../poligonos/modal_anim/CrossAnimation';
import CheckmarkAnimation from '../poligonos/modal_anim/CheckmarkAnimation';
import { useNavigate } from 'react-router-dom';
import { MapLayer, fetchLayers } from 'data/capas/capas';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';

const PuntosDetallesView = () => {
  const [layerData, setLayerData] = useState<MapLayer[]>([]);
  const [polygonData, setPolygonData] = useState<MapPolygon[]>([]);
  const [markerData, setMarkerData] = useState<MapMarker[]>([]);

  const [filteredPolygonData, setFilteredPolygonData] = useState<MapPolygon[]>([]);
  const [filteredMarkerData, setFilteredMarkerData] = useState<MapMarker[]>([]);

  const [loading, setLoading] = useState(true);

  const [selectedLayer, setSelectedLayer] = useState<MapLayer | null>(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const navigate = useNavigate();

  const { id } = useParams();

  const loadData = async () => {
    setLayerData(await fetchLayers());
    setPolygonData(await fetchPolygons());
    setMarkerData(await fetchMarkers());

    setLoading(false);
  };

  const loadLayer = async (_id: string | undefined) => {
    if (!_id) {
      alert("No se ha detectado una capa en la solicitud.");
      return;
    }
    
    const layer = layerData.find(layer => layer._id === _id);
    if (layer) {
      setSelectedLayer(layer);

      // Set filtered data
      setFilteredPolygonData(polygonData.filter(polygon => {
        if (!polygon.polygonLayer) return;

        return polygon.polygonLayer._id === layer._id;
      }));
      setFilteredMarkerData(markerData.filter(marker => {
        if (!marker.markerLayer) return;

        return marker.markerLayer._id === layer._id;
      }));

    } else {
      setShowErrorModal(true);
      setErrorModalMessage("La capa con el identificador " + _id + " no existe en la base de datos.");
    }
  };

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    setShowConfirmModal(true);
    setShowDeleteModal(false); // Cerrar la primera modal
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate('/apps/capas/capas-list-view');
  }


  const handleFinalDelete = async () => {
    if (!selectedLayer) return;

    setShowConfirmModal(false)

    const headers = {
      Authorization: getApiToken(),
    };

    axios.delete(apiUrl + '/api/layer/delete/' + selectedLayer._id, { headers })
      .then(response => {
        setShowSuccessModal(true);
      })
      .catch(error => {
        console.error('Error al enviar la solicitud para eliminar el polígono:', error);
        if (error.response && error.response.status === 404) {
          setShowErrorModal(true);
          setErrorModalMessage("La capa que intenta eliminar no existe.");
        } else {
          setShowErrorModal(true);
          setErrorModalMessage("Hubo un error al eliminar la capa seleccionada, por favor, intente más tarde.");
        }
      });
  };

  // page load
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      loadLayer(id);
    }
  }, [loading, id]);
  
  return (
    <>
    {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Cargando...</span>
        </Spinner>
      </div>
    ) : (
      <>
        <div className="mb-2">
        {selectedLayer && ( 
          <>
            <h2 className="mb-2">Capa { selectedLayer.layerName }</h2>
          </> 
        )}
        <div className="mb-3">  
          <Button
            as={Link}
            to="/apps/capas/capas-list-view"
            variant="phoenix-secondary"
            size="sm"
            startIcon={<FontAwesomeIcon icon={faListAlt} className="fs-10" />}
          >
            Volver a lista
          </Button>
        </div>
        {layerData && markerData && selectedLayer && (
          <>
          <Row className="mb-0 g-0">
            <Col xs={12} xl={12} xxl={12} sm={12}>
              <div style={{height:380, maxHeight:380}} className="mb-0 mb-xl-0 location-map border border-translucent overflow-hidden">
                { <EcomTopRegionsMap markerData={filteredMarkerData} polygonData={filteredPolygonData} /> }
              </div>
            </Col>
          </Row>
          </>
        )}
        </div>
      <>
        {(selectedLayer && 
          <>
            <Row className="gy-0 mb-0 justify-content-between" xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
              <Form>
                <Row className="mb-0 g-6" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
                  <Form.Group as={Col} controlId="Nombre" className=''>
                    <Form.Label>Nombre de la capa</Form.Label>
                    <Form.Control 
                      type="text"
                      name="layerName"
                      value={selectedLayer.layerName}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} controlId="Descripcion" className=''>
                    <Form.Label>Descripción de la capa</Form.Label>
                    <Form.Control 
                      type="text"
                      name="layerDescription"
                      value={selectedLayer.layerDescription}
                      disabled
                    />
                  </Form.Group>
                </Row>
              </Form>
            </Row><br />
                    <Button variant="danger" onClick={handleDeleteClick}>
                      {'Eliminar capa'}
                    </Button>
          </>
        )}
      </>
      { /*
      <Row className="gy-3 mb-1 justify-content-between" xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
        <Form>
          <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Nombre" className='position-relative'>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                type="text"
                name="markerName"
                value={selectedMarker?.markerName}
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} controlId="Descripcion">
              <Form.Label>Descripcion</Form.Label>
                <Form.Control
                  type="text"
                  name="markerDescription"
                  value={selectedMarker?.markerDescription}
                  disabled
                />
            </Form.Group>
            
            <Form.Group as={Col} controlId="Capa">
              <Form.Label>Capa</Form.Label>
                <Form.Control
                  type="text"
                  name="markerLayer"
                  value={selectedMarker?.markerLayer.layerName}
                  disabled
                />
            </Form.Group>
            <Form.Group as={Col} controlId="Categorias">
              <Form.Label>Categorías</Form.Label>
              <Form.Control
                type="text"
                value={selectedMarker?.markerCategory.map(category => category.categoryTitle).join(', ')}
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3 g-3" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
            <Form.Group as={Col} controlId="Direccion">
              <Form.Label>Dirección</Form.Label>
                <Form.Control
                  type="text"
                  name="markerAddress"
                  value={selectedMarker?.markerAddress}
                  disabled
                />
            </Form.Group>

            <Form.Group as={Col} controlId="Expira">
              <Form.Label>Expira</Form.Label>
              <Form.Control
                type="text"
                placeholder="Fecha de expiración"
                value={selectedMarker?.markerExpireDate.toString()}
                disabled
              />
            </Form.Group>
            
            <Form.Group controlId="markerStatus" as={Col} >
              <Form.Label>Estado</Form.Label>
              <Form.Control
                type="text"
                value={selectedMarker?.markerStatus}
                name="markerStatus"
                disabled
              />
            </Form.Group>

            <Form.Group as={Col} controlId="Icono">
              <Form.Label>Icono</Form.Label>
              <Form.Control placeholder="Icono por defecto" disabled />
            </Form.Group>
          
            <Form.Group as={Col} controlId="Latitud">
              <Form.Label>Latitud</Form.Label>
              <Form.Control type="text" value={selectedMarker?.markerLat} disabled />
            </Form.Group>
            <Form.Group as={Col} controlId="Longitud">
              <Form.Label>Longitud</Form.Label>
              <Form.Control type="text" value={selectedMarker?.markerLon} disabled />
            </Form.Group>
          </Row>
          {(selectedMarker && selectedMarker.markerTinyMce && selectedMarker.markerTinyMce !== '' &&
            <Row className="mb-3 g-1" xs={1} md={2} lg={2} xl={4} xxl={4} sm={7}>
              <Form.Group as={Col} controlId="Notas">
                <Form.Label>Notas / Enlaces</Form.Label>
                  <div dangerouslySetInnerHTML={{ __html: selectedMarker.markerTinyMce }}></div>
              </Form.Group>
            </Row>
          )}
          <Row xs={12} md={6} lg={9} xl={9} xxl={9} sm={7}>
            <Button variant="danger" onClick={handleDeleteClick}>
              {'Eliminar'}
            </Button>
          </Row> 
        </Form>
      </Row>
        */ }
      </>
    )}

      <Modal show={showDeleteModal} onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>¿Está seguro de que desea eliminar?</p>
          <ButtonGroup vertical style={{ marginTop: '10px' }}>
            <Button variant="secondary" onClick={handleCancelDelete}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleConfirmDelete} style={{ marginTop: '10px' }}>
              Confirmar
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <WarningAnimation/>
        <Modal.Body className="text-center">
          <p>¿Está completamente seguro de que desea eliminar?</p><br></br>
          <p>¡¡ESTA ACCIÓN ES IRREVERSIBLE!!</p>
          <ButtonGroup vertical style={{ marginTop: '10px' }}>
            <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={handleFinalDelete} style={{ marginTop: '10px' }}>
              Confirmar
            </Button>
          </ButtonGroup>
        </Modal.Body>
      </Modal>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Capa eliminada con éxito
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PuntosDetallesView;