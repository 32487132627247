import Avatar from 'components/base/Avatar';
import { useState } from 'react';
import { Button, Card, Dropdown, Form, Modal, Nav } from 'react-bootstrap';
import avatar from 'assets/img/testing/avatar_default.jpg';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import Scrollbar from 'components/base/Scrollbar';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { apiUrl, getApiToken } from 'helpers/utils';
import CheckmarkAnimation from 'pages/apps/poligonos/modal_anim/CheckmarkAnimation';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const navigate = useNavigate();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');

  const [navItems] = useState([
    {
      label: 'Ajustes y privacidad',
      icon: 'settings',
      link: '#ajustes'
    },
    {
      label: 'Centro de ayuda',
      icon: 'help-circle',
      link: '#ayuda'
    }
  ]);

  const handleEndSessionClick = async (e: any) => {
    e.preventDefault();
    localStorage.removeItem('apiToken');
    setSuccessModalMessage('Logout Successful');
    setShowSuccessModal(true);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessModalMessage('');

    navigate("/pages/authentication/simple/sign-in");
  };

  return (
    <>
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={avatar} size="xl" />
            <h6 className="text-body-emphasis">Administrador</h6>
          </div>
          <div style={{ height: '5rem' }}>
            <Scrollbar>
              <Nav className="nav flex-column mb-2 pb-1">
                {navItems.map(item => (
                  <Nav.Item key={item.label}>
                    <Nav.Link href={item.link} className="px-3">
                      <FeatherIcon
                        icon={item.icon}
                        size={16}
                        className="me-2 text-body"
                      />
                      <span className="text-body-highlight">{item.label}</span>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Scrollbar>
          </div>
        </Card.Body>
        <Card.Footer className="p-0 border-top border-translucent">
          <hr />
          <div className="px-3">
            <Button className="btn btn-phoenix-secondary d-flex flex-center w-100" onClick={ handleEndSessionClick }>
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              Cerrar sesión
            </Button>
          </div>
          <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
            <Link className="text-body-quaternary me-1" to="#!privacy">
              Política de privacidad
            </Link>
            •
            <Link className="text-body-quaternary mx-1" to="#conditions">
              Términos y condiciones
            </Link>
            •
            <Link className="text-body-quaternary ms-1" to="#cookies">
              Cookies
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>

    <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          {successModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseSuccessModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ProfileDropdownMenu;
