import { IconProp } from '@fortawesome/fontawesome-svg-core';
import L from 'leaflet';

import { faFileCircleExclamation, faFileCode, faFileLines, faFilePdf, faFileZipper, faImage, faMusic } from '@fortawesome/free-solid-svg-icons';
import ts, { transpile } from 'typescript';

import blank from 'assets/img/marker/blank.png';

import black from 'assets/img/marker/marker-icon-black.png';
import blue from 'assets/img/marker/marker-icon-blue.png';
import gold from 'assets/img/marker/marker-icon-gold.png';
import green from 'assets/img/marker/marker-icon-green.png';
import grey from 'assets/img/marker/marker-icon-grey.png';
import orange from 'assets/img/marker/marker-icon-orange.png';
import red from 'assets/img/marker/marker-icon-red.png';
import violet from 'assets/img/marker/marker-icon-violet.png';
import yellow from 'assets/img/marker/marker-icon-yellow.png';

import shadow from 'assets/img/marker/marker-shadow.png';

import conaf from 'assets/img/marker/conaf.png';
import ambulance from 'assets/img/marker/ambulance.png';
import firefigther from 'assets/img/marker/firefighter.png';
import police from 'assets/img/marker/police.png';

export const isAuthenticated = ( ) => {
  /*const cookies = document.cookie;
  
  if (cookies.includes("token")) {
    return true;
  } else {
    return false;
  }*/

    return hasSession();
}

export const getApiToken = () => {
  let token = localStorage.getItem("apiToken");
  if (!token) return '';
  return "Bearer " + token;
}

export const hasSession = () => {
  return getApiToken() !== '';
}

export const getSystemTheme = () =>
  window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

export const getItemFromStore = (
  key: string,
  defaultValue?: string | boolean,
  store = localStorage
) => {
  try {
    return store.getItem(key) === null
      ? defaultValue
      : JSON.parse(store.getItem(key) as string);
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const apiUrl = "https://demo.xgis.cl";
//export const apiUrl = process.env.EMILIA_BACKEND_URL;

type FormData = {
  [key: string]: any;
}

interface ColorOption {
  value: string;
  label: string;
}

interface ColorOption {
  value: string;
  label: string;
}

export const colorOptions: ColorOption[] = [
  { value: 'BLUE', label: 'Azul' },
  { value: 'GOLD', label: 'Dorado' },
  { value: 'RED', label: 'Rojo' },
  { value: 'GREEN', label: 'Verde' },
  { value: 'ORANGE', label: 'Naranja' },
  { value: 'YELLOW', label: 'Amarillo' },
  { value: 'VIOLET', label: 'Violeta' },
  { value: 'GREY', label: 'Gris' },
  { value: 'BLACK', label: 'Negro' }
];

export const customColorOptions: ColorOption[] = [
  { value: 'CONAF', label: 'Mascota de CONAF' },
  { value: 'AMBULANCE', label: 'Ambulancia' },
  { value: 'FIREFIGHTER', label: 'Bomberos' },
  { value: 'POLICEMAN', label: 'Carabineros' },
];

export const allColorOptions: ColorOption[] = colorOptions.concat(customColorOptions);

export const handleFormChange = <T extends FormData>(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, formData: T, setFormData: React.Dispatch<React.SetStateAction<T>>) => {  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value,
  });
};

export const parseContentType = (item: {
  contentDataType: string;
  contentDataRowTitle: string;
  contentDataContent: string;
}) => {
  const escapeHtml = (unsafe: string) => {
    if (!unsafe) return " ";
    return unsafe.replace(/[&<"']/g, (match) => {
      switch (match) {
        case "&":
          return "&amp;";
        case "<":
          return "&lt;";
        case ">":
          return "&gt;";
        case '"':
          return "&quot;";
        case "'":
          return "&#039;";
        default:
          return match;
      }
    });
  };

  switch (item.contentDataType) {
    case "text": {
      const escapedContent = escapeHtml(item.contentDataContent);
      return `<input type="${item.contentDataType}" value="${escapedContent}" disabled />`;
    }
    case "textarea": {
      const escapedContent = escapeHtml(item.contentDataContent);
      return `<textarea rows='4' cols='33' disabled>${escapedContent}</textarea>`;
    }
    default: {
      return "";
    }
  }
};

export const getMarkerColor = (markerColor: string) => {
  let iconUrl = '';
  let defaultIconSize = L.point(5, 21);
  let defaultIconAnchor = L.point(2, 11);
  let defaultPopupAnchor = L.point(2, -40);
  let defaultShadowUrl = shadow;

  switch (markerColor.toUpperCase()) {
    case "BLUE": {
      iconUrl = blue;
      break;
    }
    case "GOLD": {
      iconUrl = gold;
      break;
    }
    case "RED": {
      iconUrl = red;
      break;
    }
    case "GREEN": {
      iconUrl = green;
      break;
    }
    case "ORANGE": {
      iconUrl = orange;
      break;
    }
    case "YELLOW": {
      iconUrl = yellow;
      break;
    }
    case "VIOLET": {
      iconUrl = violet;
      break;
    }
    case "GREY": {
      iconUrl = grey;
      break;
    }
    case "BLACK": {
      iconUrl = black;
      break;
    }
    case "CONAF": {
      iconUrl = conaf;
      break;
    }
    case "AMBULANCE": {
      iconUrl = ambulance;
      defaultIconSize = L.point(24, 24);
      defaultShadowUrl = blank;
      break;
    }
    case "FIREFIGHTER": {
      defaultIconSize = L.point(24, 24);
      defaultShadowUrl = blank;
      iconUrl = firefigther;
      break;
    }
    case "POLICEMAN": {
      defaultIconSize = L.point(24, 24);
      defaultShadowUrl = blank;
      iconUrl = police;
      break;
    }
  }

  return L.icon({
    iconSize: defaultIconSize,
    iconAnchor: defaultIconAnchor,
    popupAnchor: defaultPopupAnchor,
    iconUrl: iconUrl,
    shadowUrl: defaultShadowUrl
  });
};

interface LightColors {
  [key: string]: string;
}

const lightColors: LightColors = {
  blue: "#ADD8E6",   // LightBlue
  gold: "#FFD700",   // LightGold (Goldenrod)
  red: "#FF7F7F",    // LightRed (LightCoral)
  green: "#90EE90",  // LightGreen
  orange: "#FFDAB9", // LightOrange (PeachPuff)
  yellow: "#FFFFE0", // LightYellow
  violet: "#EE82EE", // LightViolet (Lavender)
  grey: "#D3D3D3",   // LightGrey
  black: "#A9A9A9",  // LightBlack (DarkGrey)
};

export function getLightColor(color: string | undefined) {
  if (!color) {
    return "#000000"
  }

  return lightColors[color.toLowerCase()] || "#FFFFFF";
}

export const cleanText = (string: string) =>
  (string.charAt(0).toUpperCase() + string.slice(1))
    .replace(/-/g, ' ')
    .replace('_and_', '&');

export const setItemToStore = (
  key: string,
  payload: string,
  store = localStorage
) => store.setItem(key, payload);

export const capitalize = (string: string) =>
  (string.charAt(0).toUpperCase() + string.slice(1))
    .replace(/-/g, ' ')
    .replace('_and_', '&');

export const snakeCase = (string: string) => {
  return string
    .replace(/\W+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map(word => word.toLowerCase())
    .join('_');
};

export const getColor = (name: string) => {
  const dom = document.documentElement;
  return getComputedStyle(dom).getPropertyValue(`--phoenix-${name}`).trim();
};

/* get Dates between */
export const getDates = (
  startDate: Date,
  endDate: Date,
  interval: number = 1000 * 60 * 60 * 24
): Date[] => {
  const duration = +endDate - +startDate;
  const steps = duration / interval;
  return Array.from(
    { length: steps + 1 },
    (v, i) => new Date(startDate.valueOf() + interval * i)
  );
};

export const getPastDates = (
  duration: 'week' | 'month' | 'year' | number
): Date[] => {
  let days;

  switch (duration) {
    case 'week':
      days = 7;
      break;
    case 'month':
      days = 30;
      break;
    case 'year':
      days = 365;
      break;

    default:
      days = duration;
  }

  const date = new Date();
  const endDate = date;
  const startDate = new Date(new Date().setDate(date.getDate() - (days - 1)));
  return getDates(startDate, endDate);
};

export const currencyFormat = (
  amount: number,
  options: Intl.NumberFormatOptions = {}
) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'usd',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    ...options
  }).format(amount);
};

export const getNumbersInRange = (startAt: number, endAt: number) => {
  return [...Array(endAt + 1 - startAt).keys()].map(i => i + startAt);
};

export const numberFormat = (
  number: number,
  notation: 'standard' | 'compact' = 'standard'
) =>
  new Intl.NumberFormat('en-US', {
    notation
  }).format(number);

/* Get Random Number */
export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const getFileIcon = (fileFormat: string): IconProp => {
  switch (fileFormat) {
    case 'zip':
    case 'rar':
      return faFileZipper;
    case 'bat':
      return faFileCode;
    case 'txt':
      return faFileLines;
    case 'mad':
      return faFileCircleExclamation;
    case 'wav':
      return faMusic;
    case 'pdf':
      return faFilePdf;
    case 'jpg':
    case 'png':
    case 'jpeg':
      return faImage;
    default:
      return faFileLines;
  }
};

export const getIntegerArrayBetween = (start = 0, end: number): number[] =>
  new Array(end + 1 - start).fill(1).map((_, i) => i + start);

export const parseData = (data: string) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return [r, g, b];
};

export const rgbaColor = (color = '#fff', alpha = 0.5) =>
  `rgba(${hexToRgb(color)}, ${alpha})`;

export const transformTSCode = (
  snippet: string,
  target: ts.ScriptTarget = ts.ScriptTarget.ES2015
) =>
  transpile(snippet, {
    jsx: ts.JsxEmit.React,
    target
  });

export const getFileExtension = (fileName: string, separator = '.') =>
  fileName.split(separator).pop() || 'unknown';

export const isImageFile = (file: File) => {
  const imageMimeTypes = [
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp'
  ];
  return imageMimeTypes.includes(file.type);
};

export const convertFileToAttachment = (file: File) => ({
  name: file.name,
  size: `${(file.size / 1024).toFixed(2)} KB`,
  format: getFileExtension(file.name),
  preview: isImageFile(file) ? URL.createObjectURL(file) : undefined
});

export const getProgressColorVariant = (value: number) => {
  if (value < 21) {
    return 'warning';
  } else if (value < 41) {
    return 'info';
  } else if (value < 81) {
    return 'primary';
  } else {
    return 'success';
  }
};

export const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'urgent':
      return 'danger';
    case 'high':
      return 'warning';
    case 'medium':
      return 'success';
    case 'low':
      return 'info';
    default:
      return 'primary';
  }
};

export const invertHex = (hex: string) =>
  `#${(Number(`0x1${hex.slice(1)}`) ^ 0xffffff)
    .toString(16)
    .substring(1)
    .toUpperCase()}`;

export const getColorByBgColor = (bgColor: string) => {
  const rgba = hexToRgb(bgColor);
  if (rgba[0] * 0.299 + rgba[1] * 0.587 + rgba[2] * 0.114 > 186) {
    return 'black';
  } else {
    return 'white';
  }
};
