import React, { useContext, useEffect, useMemo, useState } from 'react';
import L, { LatLng, LatLngExpression } from 'leaflet';
import { MapContainer, Marker, Popup, useMap, useMapEvents, LayersControl, LayerGroup, TileLayer, Polygon } from 'react-leaflet';
import { CategoryRoot, ContentData, MapMarker, fetchMarkers } from 'data/puntos/puntos';
import { AppContext } from 'providers/AppProvider';
import MarkerPreviewMap from 'components/leaflet-maps/MarkerPreviewMap';

import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { MapLayer } from 'data/capas/capas';
import { apiUrl, getLightColor, getMarkerColor, parseContentType } from 'helpers/utils';
import { Col, Row, Table } from 'react-bootstrap';
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { MapPolygon, fetchPolygons } from 'data/polygon/polygons';

import "leaflet-easybutton/src/easy-button.js";
import "leaflet-easybutton/src/easy-button.css";

//import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

// @ts-ignore
import Locate from 'leaflet.locatecontrol';

import CrossAnimation from 'pages/apps/poligonos/modal_anim/CrossAnimation';
import MarkerClusterGroup from 'react-leaflet-cluster';
import MarkerDetailModal from 'components/modals/marker/MarkerDetailModal';

const LayerComponent = React.memo(({ markerData, polygonData, setShowModal, setSelectedData, setMarkerContent }:
   { markerData: MapMarker[], polygonData: MapPolygon[], setShowModal: any, setSelectedData: any, setMarkerContent: any }) => {

  const [layerGroups, setLayerGroups] = useState<{ [ key: string]: { layer: MapLayer; markers: MapMarker[]}} >({});
  const [easyButtonAdded, setEasyButtonAdded] = useState(false);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const handleShow = (layer: MapLayer, marker: MapMarker) => {
    setSelectedData({ layer, marker });
  
    const updatedMarkerContent: { id: number; contentData: ContentData[]; isOpen: boolean }[] = marker.markerData.map((contentData, index) => ({
      id: index + 1,
      contentData: [{
        contentDataType: contentData.contentDataType,
        contentDataRowTitle: contentData.contentDataRowTitle,
        contentDataContent: contentData.contentDataContent,
        contentFileNames: contentData.contentFileNames
      }],
      isOpen: false,
    }));
    setMarkerContent(updatedMarkerContent);
    
    setShowModal(true);
  };

  useEffect(() => {
    const groupedMarkersObj: { [key: string]: { layer: MapLayer; markers: MapMarker[] } } = {};

    markerData.forEach(marker => {
      const layerName = marker.markerLayer?.layerName || 'Capa por defecto';
      if (!groupedMarkersObj[layerName]) {
        groupedMarkersObj[layerName] = {
          layer: marker.markerLayer,
          markers: []
        };
      }

      groupedMarkersObj[layerName].markers.push(marker);
    });

    setLayerGroups(groupedMarkersObj);
  }, [markerData]);

  const map = useMapEvents({
    locationfound: (event) => {
      map.flyTo(event.latlng, 13.5);
    }
  });

  useEffect(() => {
    const updateMarkers = () => {
      const bounds = map.getBounds();
      const formattedBounds = {
        northEast: {
          lat: bounds.getNorthEast().lat,
          lng: bounds.getNorthEast().lng
        },
        southWest: {
          lat: bounds.getSouthWest().lat,
          lng: bounds.getSouthWest().lng
        }
      };
      console.log(formattedBounds);
    };

    map.on('moveend', updateMarkers);
    map.on('zoomend', updateMarkers);

    // Initial fetch
    updateMarkers();

    return () => {
      map.off('moveend', updateMarkers);
      map.off('zoomend', updateMarkers);
    };
  }, [map]);

  const { config } = useContext(AppContext);

  const { theme } = config;
  const filter = theme === 'dark' ? ['invert:98%','grayscale:69%','bright:89%','contrast:111%','hue:205deg','saturate:1000%']: ['bright:101%', 'contrast:101%', 'hue:23deg', 'saturate:225%'];

  useEffect(() => {
    map.invalidateSize();
  }, [config]);

  useEffect(() => {
    if (map) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const baseTileSet = (L.tileLayer as any)
        .colorFilter(
          'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
          {
            attribution: null,
            transparent: true,
            filter: filter
          }
        );

      baseTileSet.addTo(map);
      
      map.on('geosearch/showlocation', (result) => {
        // @ts-ignore
        console.log(result.location);
      });

      map.locate({setView: false, enableHighAccuracy: true});

    }
  }, [theme]);
  

  const icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    iconUrl: "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png",
    shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
  });
  
  const locateControl = new Locate({
    flyTo: true,
    keepCurrentZoomLevel: false,
    initialZoomLevel: 16.5,
    enableHighAccuracy: true,
    drawCircle: false,
    onLocationError: () => {
      setShowErrorModal(true);
      setErrorModalMessage("El usuario ha denegado el acceso a la ubicación precisa.");
    }
  });

  useEffect(() => {
    //a.addTo(map);
    if (!easyButtonAdded) {
      locateControl.addTo(map);
      setEasyButtonAdded(true);
    }
  }, []);

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: new OpenStreetMapProvider(),
    position: 'bottomleft',
    style: 'button',
    showMarker: true,
    showPopup: true,
    marker: {
      icon: icon,
      draggable: true,
    },
    maxMarkers: 1,
    maxSuggestions: 5,
    retainZoomLevel: false,
    animateZoom: true,
    searchLabel: 'Buscar dirección...',
    clearSearchLabel: 'Limpiar búsqueda',
    notFoundMessage: 'Dirección no encontrada!',
    messageHideDelay: 3000,
    zoomLevel: 18,
    classNames: {
      container: 'leaflet-control-geosearch form-control',
      button: '',
      resetButton: 'outline-success',
      msgbox: '',
      form: '',
      input: '',
      resultlist: '',
      item: '',
      notfound: '',
    },
    autoComplete: true,
    autoCompleteDelay: 250,
    autoClose: false,
    keepResult: false,
    updateMap: true,
  });

  // @ts-ignore
  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return (
    <>
      <Modal show={showErrorModal} onHide={() => {
        setShowErrorModal(false);
        setErrorModalMessage('');
      }}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => {
            setShowErrorModal(false);
            setErrorModalMessage('');
          }}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      
      <LayersControl position='topright'>
        <LayersControl.BaseLayer name="XGIS" checked>
          <TileLayer
            attribution='OpenStreet Map'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Google Map">
            <TileLayer
              attribution="Google Maps"
              url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
            />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Open Street Map">
            <TileLayer
              attribution='OpenStreet Map'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Mapa Satelital">
            <TileLayer
              attribution='Mapa Satelital'
              url="http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
            />
        </LayersControl.BaseLayer>

        {Object.entries(layerGroups).map(([layerName, { layer, markers }]) => (
          <LayersControl.Overlay key={layerName} name={layerName} checked>
            <LayerGroup>
              <MarkerClusterGroup
                chunkedLoading={true}
                removeOutsideVisibleBounds={true}
                animate={false}
              >
                {
                  markers.map(marker => (
                  <Marker
                    key={marker._id}
                    position={[marker.markerLat, marker.markerLon]}
                    icon={getMarkerColor(marker.markerIconDesign || marker.markerLayer.layerIconDesign || '')}
                  >
                    <Popup maxWidth={280} maxHeight={400} className='leaflet-popup leaflet-popup-pane leaflet-zoom-animated'>
                        <Row xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                          <Col>
                            <h5 className="mb-2 text-body-emphasis fw-semibold">{marker.markerName.toUpperCase()}</h5>
                              <Table striped bordered hover style={{ minHeight: 10, minWidth: 290}}>
                                <tbody style={{fontSize:12}}>
                                  <tr>
                                    <td className="fw-semibold" style={{minWidth:110}}> Autor: </td><td style={{minWidth:180}}>{marker.markerOwner.username}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold"> Capa designada: </td><td>{marker.markerLayer.layerName}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold"> Categoría(s): </td><td>{marker.markerCategory.map((categoria: CategoryRoot) => categoria.categoryTitle)}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold"> Estado: </td><td>{marker.markerStatus}</td>
                                  </tr>
                                </tbody>
                              </Table>
                              <p className="m-1">Indicaciones: <a target="_blank" href={"https://www.waze.com/ul?ll="+ marker.markerLat + "," + marker.markerLon + "&navigate=yes"}>Waze</a> - <a target="_blank" href={"https://maps.google.com/maps?q="+ marker.markerLat + "," + marker.markerLon + "&hl=es-CL&z=10"}>Google Maps</a></p>
                              <p className="m-1">Descripción: {marker.markerDescription}</p>
                          </Col>
                        </Row>
                        <p><Button variant="outline-success" onClick={ () => {
                          handleShow(layer, marker);
                        } }>Ver Detalles</Button></p>
                    </Popup>
                  </Marker>
                  ))
                }
              </MarkerClusterGroup>
            </LayerGroup>
          </LayersControl.Overlay>
        ))}

        <LayersControl.Overlay key={'Polígonos'} name={'Polígonos'} >
          <LayerGroup>
            {polygonData.map(polygon => (
              <Polygon
                key={polygon._id}
                positions={polygon.polygonCoords.map((coord: string) => JSON.parse(coord))}
                color={polygon.polygonLayer?.layerIconDesign}
                fillColor={getLightColor(polygon.polygonLayer?.layerIconDesign)}
                fillOpacity={0.5}
              >
                <Popup maxWidth={280} maxHeight={400} className='leaflet-popup leaflet-popup-pane leaflet-zoom-animated'>
                      <Row xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                        <Col>
                          <h6 className="mb-1 text-body-emphasis fw-semibold">{polygon.polygonName}</h6>
                            <p className="m-1">{polygon.polygonDescription}</p>
                        </Col>
                      </Row>
                </Popup>
              </Polygon>
            ))}
          </LayerGroup>
        </LayersControl.Overlay>

        <FullscreenControl forceSeparateButton={true} position="bottomright" />
      </LayersControl>
    </>
  );
});

const EcomTopRegionsMap = React.memo(({ markerData, polygonData, ...rest }: { markerData: MapMarker[], polygonData: MapPolygon[] }) => {
  const position: LatLngExpression = [-33.537, -70.72827];
  const {
    config: { isRTL }
  } = useContext(AppContext);

  const [selectedData, setSelectedData] = useState<{ layer: MapLayer; marker: MapMarker }>({
      layer: {} as MapLayer,
      marker: {} as MapMarker,
  });
  const [markerContent, setMarkerContent] = useState<{ id: number, contentData: ContentData[], isOpen: boolean }[]>([]);
  
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setMarkerContent([]);
    setShowModal(false);
  };
  
  return (
    <MapContainer
      preferCanvas={true}
      bounceAtZoomLimits={true}
      zoom={isRTL ? 10.8 : 10.5}
      minZoom={isRTL ? 1.8 : 1.3}
      zoomSnap={0.5}
      center={position}
      {...rest}
      className="h-100 bg-body-emphasis"
    >
    
    <LayerComponent markerData={markerData} polygonData={polygonData} setShowModal={setShowModal} setSelectedData={setSelectedData} setMarkerContent={setMarkerContent}/>

    <MarkerDetailModal show={showModal} handleClose={handleClose} selectedData={selectedData} markerContent={markerContent} />
    
    </MapContainer>
  );
});

export default EcomTopRegionsMap;
