import axios from 'axios';

import { Status } from 'components/base/Avatar';

import { BadgeBg } from 'components/base/Badge';
import { Member } from 'data/users';
import { User } from 'data/users';
import { apiUrl, getApiToken } from 'helpers/utils';

export interface LayerCategoryRoot {
  categoryTitle: string;
  categoryDesc: string;
}

export interface LayerContentData {
  contentDataType: string;
  contentDataRowTitle: string;
  contentDataContent: string;
  contentFileNames: string[];
}

export interface MapLayer {
  _id: string;
  layerName: string;
  layerDescription: string;
  layerOwner: User;
  layerStatus: string;
  layerSku: number;
  layerMarkerLimit: number;
  layerInsertDate: Date;
  layerExpireDate: Date;
  layerIconDesign: string;
  layerAssignations: [User];
  layerTemplateCategory: [LayerCategoryRoot];
  layerTemplateContent: [LayerContentData];
};

export const fetchLayers = async (): Promise<MapLayer[]> => {
  try {
    const headers = {
      Authorization: getApiToken(),
    };

    const response = await axios.get(apiUrl + '/api/layer/list', { headers });
    
    return response.data.content;
  } catch (error) {
    console.error('Error fetching layers:', error);
    return [];
  }
};

export interface Capa {
  id: number;
  name: string;
  start: string;
  deadline: string;
  calculation?: {
    amount: string;
    label: string;
  };
  assigness: Member[];
  progress: {
    min: number;
    max: number;
  };
  task: number;
  statusProgress: Status;
  status: {
    label: string;
    type: BadgeBg;
  };
  bg: string;
  budget: number;
}
/*
export const capas: Capa[] = [
  {
    id: 1,
    name: 'EMPRESAS QUIMICAS',
    start: 'Dec 12, 2018',
    deadline: 'admin',
    calculation: {
      amount: '$4',
      label: 'Cost'
    },
    assigness: [0].map(index => members[index]),
    progress: {
      min: 145,
      max: 145
    },
    statusProgress: {
      ongoing: 30,
      critical: 5,
      inactive: 45,
      completed: 15
    },
    task: 287,
    status: {
      label: 'activa',
      type: 'success'
    },
    bg: bg51,
    budget: 3991
  }
]; */
