import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CapasTopSection from 'components/modules/capas/CapasTopSection';
import LayerListTable, { layerListTableColumns } from 'components/tables/CapasListTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useState, useEffect } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import ReactSelect from 'react-select';

import { User, fetchUsers } from 'data/users';
import { LayerCategoryRoot, LayerContentData, MapLayer, fetchLayers } from 'data/capas/capas';

import axios from 'axios';
import { allColorOptions, apiUrl, getApiToken } from 'helpers/utils';
import CrossAnimation from '../poligonos/modal_anim/CrossAnimation';
import CheckmarkAnimation from '../poligonos/modal_anim/CheckmarkAnimation';

const CapasListView = () => {
  const [userData, setUserData] = useState<User[]>([]);
  const [layerData, setLayerData] = useState<MapLayer[]>([]);

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const [layerTemplateCategories, setLayerTemplateCategories] = useState<LayerCategoryRoot[]>([{ categoryTitle: "", categoryDesc: "" }]);

  const [layerTemplateContents, setLayerTemplateContents] = useState<LayerContentData[]>([{ contentDataType: 'text', contentDataRowTitle: '', contentDataContent: '', contentFileNames: [] }]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState('');

  const [formData, setFormData] = useState<{
    layerName: string;
    layerDescription: string;
    layerStatus: string;
    layerSku: string;
    layerMarkerLimit: string;
    layerIconDesign: string;
    layerAssignations: string[];
    publicAsset: boolean;
    layerTemplateCategory: LayerCategoryRoot[];
    layerTemplateContent: LayerContentData[];
  }>({
    layerName: '',
    layerDescription: '',
    layerStatus: 'activa',
    layerSku: '0',
    layerMarkerLimit: '200',
    layerIconDesign: 'BLUE',
    layerAssignations: [],
    publicAsset: false,
    layerTemplateCategory: [{
      categoryTitle: '',
      categoryDesc: '',
    }],
    layerTemplateContent: [{
      contentDataType: 'text',
      contentDataRowTitle: '',
      contentDataContent: '',
      contentFileNames: []
    }]
  });

  const table = useAdvanceTable({
    data: layerData,
    columns: layerListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true,
    selection: true
  });

  const loadData = async () => {
    setUserData(await fetchUsers());
    setLayerData(await fetchLayers());
  };

  const [statusOptions] = useState([
    { value: 'activa', label: 'Activa' },
    { value: 'deshabilitada', label: 'Inactiva' },
  ]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleAssignationsChange = (selectedOptions: any) => {
    const selectedAssignations = selectedOptions.map((option: any) => option.value);

    setFormData(prevState => ({
      ...prevState,
      layerAssignations: selectedAssignations
    }));
  }

  const handleStatusChange = (e: any) => {
    const { value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      layerStatus: value
    }));
  }

  const handleIconDesignStatusChange = (e: any) => {
    const { value } = e.target;

    console.log(value);

    setFormData(prevState => ({
      ...prevState,
      layerIconDesign: value
    }));
  }

  const handlePublicAssetChange = (e: any) => {
    const { value } = e.target;

    console.log(value);

    setFormData(prevState => ({
      ...prevState,
      publicAsset: e.target.value === 'true'
    }));
  }

  const handleMarkerLimitChange = (e: any) => {
    const { value } = e.target;

    console.log(value)
    setFormData(prevState => ({
      ...prevState,
      layerMarkerLimit: value
    }));
  }

  // Categorias
  const handleAddInput = () => {
    setLayerTemplateCategories([...layerTemplateCategories, { categoryTitle: "", categoryDesc: "" }]);
  };

  const handleChange2 = (event: React.ChangeEvent<any>, index: number) => {
    const { name, value } = event.target;
    const onChangeValue = [...layerTemplateCategories];
    onChangeValue[index] = { ...onChangeValue[index], [name]: value };

    setLayerTemplateCategories(onChangeValue);
  };
  
  const handleDeleteInput = (index: any) => {
    const newArray = [...layerTemplateCategories];
    newArray.splice(index, 1);
    setLayerTemplateCategories(newArray);
  };

  // Content
  const handleAddContentInput = () => {
    const newContent: LayerContentData = {
      contentDataType: 'text',
      contentDataRowTitle: '',
      contentDataContent: '',
      contentFileNames: []
    };

    setLayerTemplateContents([...layerTemplateContents, newContent]);

    setFormData(prevState => ({
      ...prevState,
      layerTemplateContent: [...prevState.layerTemplateContent, newContent]
    }));
  };
  
  const handleChange3 = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
    const { name, value } = event.target;
    const updatedContents = [...layerTemplateContents];
    updatedContents[index] = { ...updatedContents[index], [name]: value };

    setLayerTemplateContents(updatedContents);

    console.log(updatedContents);
  };

  const handleChange4 = (event: React.ChangeEvent<any>, index: number) => {
    const { name, value } = event.target;
    const updatedContents = [...layerTemplateContents];
    updatedContents[index] = { ...updatedContents[index], [name]: value };

    setLayerTemplateContents(updatedContents);

    console.log(updatedContents);
  };
  
  const handleDeleteContentInput = (index: number) => {
    const updatedContents = [...layerTemplateContents];
    updatedContents.splice(index, 1);

    setLayerTemplateContents(updatedContents);
  };

  const handleSave = () => {
    if (!formData.layerName || !formData.layerDescription || formData.layerAssignations.length === 0) {
      setErrorModalMessage("Por favor complete todos los campos obligatorios.");
      setShowErrorModal(true);

      return;
    }

    setLoading(true);

    // Copy cats
    formData.layerTemplateCategory = layerTemplateCategories;

    // Copy datas
    formData.layerTemplateContent = layerTemplateContents;

    const headers = {
      Authorization: getApiToken(),
    };

    axios.put(apiUrl + '/api/layer/add', formData, { headers })
      .then(response => {
        setFormData({
          layerName: '',
          layerDescription: '',
          layerStatus: 'activa',
          layerSku: '0',
          layerMarkerLimit: '200',
          layerIconDesign: 'BLUE',
          layerAssignations: [],
          publicAsset: false,
          layerTemplateCategory: [
            {
              categoryTitle: '',
              categoryDesc: ''
            }
          ],
          layerTemplateContent: [
            {
              contentDataType: 'text',
              contentDataRowTitle: '',
              contentDataContent: '',
              contentFileNames: []
            }
          ]
        });

        setLayerTemplateCategories([ { categoryTitle: "", categoryDesc: "" } ]);
        setLayerTemplateContents([ { contentDataType: 'text', contentDataRowTitle: '', contentDataContent: '', contentFileNames: [] } ]); // Ahora queda vacío

        setShowSuccessModal(true);

        loadData();

        handleClose();
      })
      .catch(error => {
        console.error('Error al enviar la solicitud:', error);
        setErrorModalMessage("Ha ocurrido un error al enviar la solicitud.");
        setShowErrorModal(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const debugForm = () => {
        // Copy cats
        formData.layerTemplateCategory = layerTemplateCategories;

        // Copy datas
        formData.layerTemplateContent = layerTemplateContents;

    alert(JSON.stringify(formData))
  }

  // page load
  useEffect(() => {
    loadData();
  }, []);
  
  return (
    <>
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-6 gap-3 gap-sm-6 align-items-center">
          <h2 className="mb-0">
            <span className="me-1">Administración de capas</span>{' '}
            <span className="fw-normal text-body-tertiary">
              ({layerData.length})
            </span>
          </h2>
          <Button onClick={handleShow} variant="outline-primary" className="me-2 px-2" to="/">
            <FontAwesomeIcon icon={faPlus} className="me-2 align-items-center" />
            Añadir nueva capa
          </Button>
          <Modal size="lg" show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Agregar nueva capa</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Row xs={1} md={2} lg={4} xl={4} xxl={4} sm={7}>
                        <Form.Group controlId="NombreCapa" as={Col} >
                          <Form.Label>Nombre Capa</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Zonas con riesgo de incendio"
                            name="layerName"
                            value={formData.layerName}
                            onChange={handleChange}
                            disabled={loading}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Descripción</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Descripción de su capa"
                          name="layerDescription"
                          value={formData.layerDescription}
                          onChange={handleChange}
                          disabled={loading}
                        />
                      </Form.Group>
                        <Form.Group controlId="MarkersLimit" as={Col} >
                          <Form.Label>Límite de Registros</Form.Label>
                          <Form.Select
                            value={formData.layerMarkerLimit}
                            name="layerMarkerLimit"
                            onChange={handleMarkerLimitChange}
                            disabled={loading}
                          >
                            <option value="200">200</option>
                            <option value="300">300</option>
                            <option value="400">400</option>
                            <option value="-1">Ilimitada</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group controlId="layerStatus" as={Col} >
                          <Form.Label>Estado</Form.Label>
                          <Form.Select
                            value={formData.layerStatus}
                            name="layerStatus"
                            onChange={handleStatusChange}
                            disabled={loading}
                          >
                          {statusOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      <Row xs={12} md={12} lg={14} xl={14} xxl={14} sm={17}>
                        <Form.Group controlId="layerIconDesign" as={Col} >
                          <Form.Label>Color de íconos por defecto</Form.Label>
                          <Form.Select
                            value={formData.layerIconDesign}
                            name="layerIconDesign"
                            onChange={handleIconDesignStatusChange}
                            disabled={loading}
                          >
                          {allColorOptions.map(option => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                          </Form.Select>
                        </Form.Group>
                        <Form.Group controlId="Asignar" as={Col} >
                          <Form.Label>Asignada a:</Form.Label>
                          <ReactSelect className="react-select-container" classNamePrefix="react-select" 
                            options={(userData as { _id: string; username: string }[]).map(member => ({ value: member._id, label: member.username }))}
                          isMulti
                          noOptionsMessage={() => "No hay mas usuarios"}
                          placeholder="Seleccione usuarios"
                          isDisabled={loading}
                          onChange={handleAssignationsChange}
                          />
                        </Form.Group>
                      </Row>
                      <Row xs={12} md={12} lg={14} xl={14} xxl={14} sm={17}>
                        <Form.Group controlId="Public" as={Col} >
                          <Form.Label>Capa Pública</Form.Label>
                            <Form.Select
                              value={String(formData.publicAsset)} // convert boolean to string
                              name="publicAsset"
                              onChange={handlePublicAssetChange}
                              disabled={loading}
                            >
                              <option value="false">No</option> {/* default */}
                              <option value="true">Sí</option>
                            </Form.Select>
                        </Form.Group>
                      </Row>
                          <h5 className="fs-9 mb-1 py-3">
                            <span className="me-5">Opciones Avanzadas</span>
                          </h5>
                        <Row xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                          <span className="fs-9">
                            <span className="me-5">Categorías (*)</span>
                          </span>
                          {layerTemplateCategories.map((item, index) => (
                          <div className="d-flex flex-wrap mb-1 gap-1 gap-sm-4 align-items-center" key={index}>
                            <Form.Group>
                              <Form.Label>Nombre Categoría</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Título de la categoría"
                                    name="categoryTitle"
                                    value={item.categoryTitle}
                                    onChange={(event) => handleChange2(event, index)}
                                    disabled={loading}
                                  />
                                
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Descripción</Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder="Descripción de la categoría"
                                      name="categoryDesc"
                                      value={item.categoryDesc}
                                      onChange={(event) => handleChange2(event, index)}
                                      disabled={loading}
                                    />
                              </Form.Group>
                            <Form.Group>
                                  {layerTemplateCategories.length > 1 && (
                                    <Button variant="outline-danger" className="me-2 px-2" onClick={() => handleDeleteInput(index)}>- Eliminar</Button>
                                  )}
                                  {index === layerTemplateCategories.length - 1 && (
                                    <Button variant="outline-success" className="me-2 px-2" onClick={() => handleAddInput()}>+ Agregar</Button>
                                  )}
                            </Form.Group>
                          </div>
                        ))}
                        </Row>
                        
                      <span className="fs-9 mb-1 py-3">
                        <span className="me-5">Formulario Marca</span>
                      </span>

                      <Row xs={12} md={12} lg={12} xl={12} xxl={12} sm={12}>
                      {layerTemplateContents.map((item, index) => (
                          <div className="d-flex flex-wrap mb-1 gap-1 gap-sm-4 align-items-center" key={index}>
                            <Form.Group>
                              <Form.Label>Tipo de campo</Form.Label>
                            <Form.Select
                              defaultValue={item.contentDataType}
                              name="contentDataType"
                              onChange={(event) => handleChange4(event, index)}
                              disabled={loading}
                            >
                              <option value="text">Entrada de texto</option>
                              <option value="textarea">Área de texto</option>
                              <option value="file">Archivo</option>
                              <option value="date" disabled>Fecha</option>
                              <option value="selection" disabled>Selección</option>
                            </Form.Select>
                            </Form.Group>
                            <Form.Group>
                              <Form.Label>Título del campo</Form.Label>
                                  <Form.Control
                                      type="text"
                                      placeholder="Título de ejemplo"
                                      name="contentDataRowTitle"
                                      value={item.contentDataRowTitle}
                                      onChange={(event) => handleChange3(event, index)}
                                      disabled={loading}
                                    />
                            </Form.Group>
                            <Form.Group>
                            <Form.Label>Descripción del campo</Form.Label>
                            {item.contentDataType === 'text' && (
                              <Form.Control
                                type="text"
                                placeholder="Descripción de ejemplo"
                                name="contentDataContent"
                                value={item.contentDataContent}
                                onChange={(event) => handleChange3(event, index)}
                                disabled={loading}
                              />
                            )}
                            {item.contentDataType === 'textarea' && (
                              <Form.Control
                                as="textarea"
                                placeholder="Descripción de ejemplo"
                                name="contentDataContent"
                                value={item.contentDataContent}
                                onChange={(event) => handleChange3(event, index)}
                                disabled={loading}
                              />
                            )}
                            {item.contentDataType === 'file' && (
                              <Form.Control
                                type="text"
                                placeholder="No disponible"
                                name="contentDataContent"
                                disabled
                              />
                            )}
                            </Form.Group>
                            <Form.Group>
                                  {layerTemplateContents.length > 1 && (
                                    <Button variant="outline-danger" className="me-2 px-2" onClick={() => handleDeleteContentInput(index)}>- Eliminar</Button>
                                  )}
                                  {index === layerTemplateContents.length - 1 && (
                                    <Button variant="outline-success" className="me-2 px-2" onClick={() => handleAddContentInput()}>+ Agregar</Button>
                                  )}
                            </Form.Group>
                          </div>
                        ))}
                      </Row>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={debugForm} disabled={loading}>
                      Debug
                    </Button>
                    <Button variant="secondary" onClick={handleClose} disabled={loading}>
                      Cerrar
                    </Button>
                    <Button variant="primary" onClick={handleSave} disabled={loading}>
                      {loading ? 'Guardando...' : 'Guardar'}
                    </Button>
                  </Modal.Footer>
                </Modal>
        </div>
        <CapasTopSection activeView="list" />
        <LayerListTable />
      </AdvanceTableProvider>
    </div>

    <>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CheckmarkAnimation />
          Capa insertada con éxito
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <CrossAnimation />
          {errorModalMessage}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    </>
  );
};

export default CapasListView;